import React from 'react';
import { Loader } from 'react-helpers';
import useImagePreloader from './hooks/useImagePreloader';
import theGatheringImage from './assets/images/events/the-gathering.png';
import mexicoImage from './assets/images/events/mexico.png';
import offSiteImage from './assets/images/events/off-site.png';
import blackLabelImage from './assets/images/events/black-label.png';
import theGatheringPoster from './assets/images/events/the-gathering-poster.png';
import mexicoPoster from './assets/images/events/mexico-poster.png';
import offSitePoster from './assets/images/events/off-site-poster.png';
import blackLabelPoster from './assets/images/events/black-label-poster.png';
import AcademyImage from './assets/images/dashboard/ua.png';
import rewindImage from './assets/images/dashboard/reweind.png';
import lastCallStoryImage from './assets/images/dashboard/last-call.png';
import vitlLiveImage from './assets/images/dashboard/vitl-live.png';
import lastCallOverviewImage from './assets/images/dashboard/last-call-overview.png';
import storeImage from './assets/images/dashboard/store.png';
import gradient from './assets/images/gradient.png';
import classroom from './assets/images/classroom.png';
import lastCallbackground from './assets/images/competitions/last-call/background.png';
import lvlsBackground from './assets/images/competitions/lvls/background.png';
import bookshelf from './assets/images/uncommon-academy/bookshelf.png';
import lvlsImage from './assets/images/competitions/dashboard/lvls-letters.png';
import installs15 from './assets/images/competitions/lvls/installs-15.png';
import installs25 from './assets/images/competitions/lvls/installs-25.png';
import installs50 from './assets/images/competitions/lvls/installs-50.png';
import installs60 from './assets/images/competitions/lvls/installs-60.png';
import installs75 from './assets/images/competitions/lvls/installs-75.png';
import installs100 from './assets/images/competitions/lvls/installs-100.png';
import firstChairImage from './assets/images/competitions/dashboard/first-chair.png';
import hiitImage from './assets/images/competitions/dashboard/hiit.png';
import pulseImage from './assets/images/competitions/dashboard/pulse.png';
import lastCallImage from './assets/images/competitions/dashboard/last-call.png';
import firstChairPoster from './assets/images/competitions/first-chair/first-chair-poster.png';
import hiitChairPoster from './assets/images/competitions/hiit/hiit-poster.png';
import pulsePoster from './assets/images/competitions/pulse/pulse-poster.png';
import UncommonAcademyLogo from './assets/images/uncommon-academy/uncommon-academy-logo.png';
import WoodFrame from './assets/images/uncommon-academy/wood-frame.png';
import VideoCover from './assets/images/uncommon-academy/xcover.png';
import GenericVideoThumbnail from './assets/images/uncommon-academy/generic-video-thumbnail.png';

interface PreloadedImagesProviderProps {
    children: React.ReactNode;
}

export const IMAGES = {
    mexicoImage,
    offSiteImage,
    blackLabelImage,
    theGatheringPoster,
    theGatheringImage,
    mexicoPoster,
    offSitePoster,
    blackLabelPoster,
    AcademyImage,
    rewindImage,
    lastCallStoryImage,
    vitlLiveImage,
    lastCallOverviewImage,
    storeImage,
    gradient,
    classroom,
    lastCallbackground,
    lvlsBackground,
    bookshelf,
    lvlsImage,
    installs15,
    installs25,
    installs50,
    installs60,
    installs75,
    installs100,
    firstChairImage,
    hiitImage,
    pulseImage,
    lastCallImage,
    firstChairPoster,
    hiitChairPoster,
    pulsePoster,
    UncommonAcademyLogo,
    WoodFrame,
    VideoCover,
    GenericVideoThumbnail,
};

const ImagePreloader = ({ children }: PreloadedImagesProviderProps) => {
    useImagePreloader(Object.values(IMAGES));
    return children;
};

export default ImagePreloader;
